@tailwind base;
@tailwind components;
@tailwind utilities;
.gallery {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.g-border {
	border-image: linear-gradient(to right, #016dea 27.41%, #fa0101 95.02%) 1;
	/* linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%) */
	border-radius: 9999px;
	border-width: 2px;
	border-style: solid;
}

.shadow-top {
	box-shadow: 0px -2px 30px -3px rgba(209, 200, 200, 0.75);
	-webkit-box-shadow: 0px -2px 30px -3px rgba(209, 200, 200, 0.75);
	-moz-box-shadow: 0px -2px 30px -3px rgba(209, 200, 200, 0.75);
}

.gradient-text {
	background: linear-gradient(62.47deg, #016dea 27.41%, #fa0101 95.02%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.login-input .PhoneInputCountry {
	background: transparent;
}

[contenteditable='true'] {
	outline: none;
}

.infinite-scrollbar::-webkit-scrollbar {
	display: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: transparent !important;
}

.infinite-scrollbar::-webkit-scrollbar-track {
	display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #e5e5e5;
	border-radius: 2px;
}

.infinite-scrollbar::-webkit-scrollbar-thumb {
	display: none;
}

.tiptap {
	code {
		background-color: #016dea44;
		border-radius: 0.4rem;
		color: black;
		font-size: 0.85rem;
		padding: 0.25em 0.3em;
	}
}

.slick-slide {
	padding: 0 8px;
}

.slick-arrow::before {
	display: none;
}

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}

	.btn {
		padding: 20px 60px;
		border: none;
		outline: none;
		position: relative;
		z-index: 1;
		background: linear-gradient(to right, #00ffa3, #dc1fff);
		cursor: pointer;
	}

	.btn::before {
		content: '';
		position: absolute;
		left: 1px;
		right: 1px;
		top: 1px;
		bottom: 1px;
		border-radius: 9999px;
		background-color: white;
		z-index: -1;
		transition: 200ms;
	}

	.btn::after {
		content: attr(data);
		font-size: 16px;
		background: linear-gradient(to left, #00ffa3, #dc1fff);
		-webkit-background-clip: text;
		color: transparent;
		transition: 200ms;
	}

	.btn:hover::before {
		opacity: 50%;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
	}

	.btn:hover::after {
		color: white;
	}
}

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}

@layer components {
	.custom-shadow-sm {
		@apply shadow-[0px_4px_10px_0px_#0000000d];
	}

	.swiper {
		@apply w-full;
	}

	.custom-shadow-sm-spread {
		@apply shadow-[0px_5px_50px_0px_#0000001A];
	}

	.custom-shadow-md {
		@apply shadow-[0px_0px_10px_0px_#00000040];
	}

	.custom-shadow-lg {
		@apply shadow-[0px_0px_10px_0px_#00000026];
	}

	.default-gradient {
		@apply bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)];
	}

	.default-border-gradient {
		@apply bg-gradient-to-r from-[#FA0101] to-[#016DEA];
	}
	.default-border-gradient {
		@apply bg-gradient-to-r from-[#FA0101] to-[#016DEA];
	}
	.red-gradient {
		@apply bg-[linear-gradient(90deg,#FA4001_0%,#CD0D14_33.33%,#A91D1E_66.66%,#812924_100%)];
	}
	.red-gradient-reverse {
		@apply bg-[linear-gradient(90deg,#812924_0%,#A91D1E_30.73%,#CD0D14_64.58%,#FA4001_100%)];
	}

	.blue-gradient {
		@apply bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)];
	}
	.blue-gradient-reverse {
		@apply bg-[linear-gradient(90deg,#003B7E_0%,#0052B1_30.73%,#005FCD_65.63%,#016DEA_100.01%)];
	}

	.green-gradient {
		@apply bg-[linear-gradient(90deg,#02903F,#0ACD5E)];
	}

	.secondary-gradient {
		@apply bg-[linear-gradient(270deg,#5D0076_0%,#9D01C8_100%)];
	}

	.secondary-hover-gradient {
		@apply bg-[linear-gradient(270deg,#9D01C8_0%,#5D0076_100%)];
	}

	.secondary-border-gradient {
		@apply bg-gradient-to-r from-[#5200A5] to-[#7F00FF];
	}

	.hide-scrollbar::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	.hide-scrollbar {
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
}

@layer utilities {
	.red-blue-gradient {
		background-image: linear-gradient(90deg, #fa0101 0%, #016dea 100%);
	}
	.blue-red-gradient {
		background-image: linear-gradient(62deg, #016dea 27.41%, #fa0101 95.02%);
	}
	.purple-gradient {
		background-image: linear-gradient(270deg, #5d0076 0%, #9d01c8 100%);
	}
	.purple-gradient-reverse {
		background-image: linear-gradient(270deg, #9d01c8 0%, #5d0076 100%);
	}
	.violet-gradient {
		background-image: linear-gradient(270deg, #7f00ff 0%, #5200a5 100%);
	}
	.malachite-gradient {
		background-image: linear-gradient(90deg, #02903f 0%, #0acd5e 100%);
	}

	.border-gradient-custom {
		position: relative;
		z-index: 1;
	}

	.border-gradient-custom::before {
		content: '';
		position: absolute;
		top: -0.5px;
		left: -0.5px;
		right: -0.5px;
		bottom: -0.5px;
		background: linear-gradient(242deg, #016dea 27.41%, #fa0101 95.02%);
		z-index: -1;
		border-radius: inherit;
	}
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

.scrollbar-hide {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

.login-small-ellipse-animation {
	animation: loginSmallEllipseAnimation;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
}

.login-ellipse-animation {
	animation: loginEllipseAnimation;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

@keyframes loginEllipseAnimation {
	0% {
		opacity: 0.3;
		bottom: -32px;
		right: -32px;
	}
	60% {
		opacity: 0.3;
		bottom: -32px;
		right: -32px;
	}
	100% {
		opacity: 1;
		bottom: -16px;
		right: -16px;
	}
}

@keyframes loginSmallEllipseAnimation {
	0% {
		opacity: 0.3;
		bottom: -24px;
		right: -20px;
	}
	90% {
		opacity: 0.3;
		bottom: 24px;
		right: 20px;
	}
	100% {
		opacity: 1;
		bottom: 24px;
		right: 20px;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spin-slow {
	animation: spin 3s linear infinite;
}

.border-gradient {
	border-image: linear-gradient(62deg, #016dea 27.41%, #fa0101 95.02%) 1;
	border-style: dashed;
	border-width: 2px;
}

/* Base styles for the radio button */
input[type='radio'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid #b0b0b0;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	position: relative;
}

/* Styles for the selected state */
input[type='radio']:checked {
	border: 1px solid #007bff;
}

/* Styles for the inner circle (dot) */
input[type='radio']:checked::before {
	content: '';
	width: 6px;
	height: 6px;
	background: #007bff; /* Blue dot */
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Border styles for the radio button */
.border-style-radio {
	width: 16px !important;
	height: 16px !important;
}

.border-style-radio:checked {
	background-color: #007bff !important;
}

/* Styles for the inner circle (dot) */
.border-style-radio:checked::before {
	width: 8px !important;
	height: 8px !important;
	background: white !important;
}

input[type='checkbox'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 16px; /* Adjust the size as needed */
	height: 16px;
	/* background-color: white; */
	border: 1px solid #aaa9ad; /* Border color */
	border-radius: 2px;
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
	transition:
		background-color 0.3s,
		border-color 0.3s;
}

input[type='checkbox']:checked {
	background: linear-gradient(90deg, #016dea 0%, #005fcd 33.41%, #0052b1 63.49%, #003b7e 100.01%);
	border-color: #016dea;
}

input[type='checkbox']:checked::after {
	content: '';
	position: absolute;
	top: 45%;
	left: 50%;
	width: 4px; /* Width of the tick */
	height: 9px; /* Height of the tick */
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: translate(-50%, -50%) rotate(45deg);
}

.PhoneInputCountry {
	background-color: #f2f2f2;
	padding-inline-start: 15px;
	padding-inline-end: 15px;
	padding-top: 13px;
	padding-bottom: 13px;
	margin-right: 0px !important;
	margin-inline-end: 5px !important;
}

.PhoneInputInput {
	outline: none;
}

.PhoneInputInput:disabled {
	background-color: #f2f2f2 !important;
}

.ql-toolbar,
.ql-container {
	border: 0px !important;
}

.ql-editor.ql-blank::before {
	font-family: var(--font-poppins) !important;
	font-size: 14px !important;
	--tw-text-opacity: 1 !important;
	color: var(--black-50, rgba(0, 0, 0, 0.5)) !important;
	font-weight: 500;
	font-style: normal !important;
	line-height: 22px;
}

.ql-editor {
	padding-top: 2px !important;
}

.ql-container,
.ql-editor {
	font-size: 14px !important;
	line-height: 22.4px !important;
}

.timeinput div[aria-label='AM/PM'] {
	display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.lg-react-element {
	display: grid !important;
	grid-template-columns: repeat(4, 1fr) !important;
	gap: 11px !important;
	border-radius: 0.375rem !important;
}

.modal-overlay {
	z-index: 9999 !important;
}

.referral-container {
	@apply mx-2 sm:mx-3 md:mx-4 lg:mx-5 2xl:mx-0;
}

.custom-rounded {
	position: relative;
	background: linear-gradient(242deg, #016dea 27.41%, #fa0101 95.02%);
	border-radius: 12px;
	border-style: solid;
}

.custom-rounded::after {
	border-radius: 10px;
	position: absolute;
	content: '';
	width: 99%;
	background-color: white;
	height: 66px;
	top: 2px;
	left: 2px;
}

.custom-rounded::after:hover {
	background: linear-gradient(242deg, #016dea 27.41%, #fa0101 95.02%);
}

.custom-rounded-btn {
	position: relative;
	background: linear-gradient(242deg, #016dea 27.41%, #fa0101 95.02%);
	border-radius: 15px;
	border-style: solid;
}

.custom-rounded-btn:after {
	border-radius: 15px;
	position: absolute;
	content: '';
	width: 97%;
	background-color: white;
	height: 26px;
	top: 1px;
	left: 1px;
}
.custom-text-gradient {
	@apply whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins text-[0.8rem] font-semibold not-italic leading-6 text-transparent;
}
.fe-page-shadow {
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.plan-shadow {
	box-shadow: 0px 0px 125px 0px rgba(0, 0, 0, 0.1);
}

.plan-price {
	background: linear-gradient(90deg, #016dea 0%, #005fcd 33.41%, #0052b1 63.49%, #003b7e 100.01%);
}

.plan-price-border {
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: Fill (85px) px;
	height: Hug (40px) px;
	padding: 9px 15px 9px 15px;
	gap: 10px;
	border-radius: 6px;
	border: 1px 0px 0px 0px;
	opacity: 0px;
}

.plan-cancel-button {
	background: linear-gradient(270deg, #5d0076 0%, #9d01c8 100%);
	transition: background 0.3s ease;
}

.plan-cancel-button:hover {
	background: linear-gradient(90deg, #5d0076 0%, #9d01c8 100%);
}

.my-storage-table-header {
	background: linear-gradient(90deg, #016dea 0%, #005fcd 33.41%, #0052b1 63.49%, #003b7e 100.01%);
}

.hover-icon .hover-path {
	transition: stroke 0.3s ease;
}

.hover-icon:hover .hover-path {
	stroke: white;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
	@apply font-poppins text-sm font-medium leading-[160%] text-[var(--black-50)];
}

.approve-btn {
	@apply bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)];
}

.approve-btn:hover {
	background-image: linear-gradient(270deg, #5d0076 0%, #9d01c8 100%);
}

.red-orange-gradient {
	background-image: linear-gradient(90deg, #fa4001 0%, #cd0d14 33.33%, #a91d1e 66.66%, #812924 100%);
}
@media (max-width: 1022px) {
	.responsive-col {
		gap: 7.8rem;
	}
}
.custom-shadow {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.shadow-Box {
	box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}
.hide-scrollbar {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

.sidebar-Button {
	position: fixed;
	z-index: 10;
	left: 20px;
	bottom: 10px;
	box-shadow: 2px 2px 10px 1px white;
	border-radius: 50%;
}

.border-gradient-container {
	background: transparent;
	background-image: linear-gradient(90deg, white, white), linear-gradient(62deg, #016dea 27.41%, #fa0101 95.02%);
	overflow: hidden;
	background-clip: padding-box, border-box;
	background-origin: border-box;
	border: 1px solid transparent;
	border-radius: 10px;
}

/* .quill .ql-toolbar.ql-snow {
	min-height: 88px;
} */
